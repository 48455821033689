.main-header {
	.bootstrap-button-group {
		justify-content: center;
		margin-bottom: 0;
		.btn-group {
			flex-wrap: wrap;
			.btn {
				line-height: 50px;
				border-radius: 0;
				border: 0;
				font-size: 1.25rem;
				font-weight: 500;
				padding-left: 30px;
				padding-right: 30px;
				min-width: 300px;
				margin-right: 3px;
				margin-bottom: 1px;
				&:nth-child(3n-2) {
					background-color: $main-header-button-group-bg-color-1;
					color: $main-header-button-group-text-color-1;
					&:hover {
						background-color: $main-header-button-group-hover-bg-color-1;
					}
				}
				&:nth-child(3n-1) {
					background-color: $main-header-button-group-bg-color-2;
					color: $main-header-button-group-text-color-2;
					&:hover {
						background-color: $main-header-button-group-hover-bg-color-2;
					}
				}
				&:nth-child(3n) {
					background-color: $main-header-button-group-bg-color-3;
					color: $main-header-button-group-text-color-3;
					&:hover {
						background-color: $main-header-button-group-hover-bg-color-3;
					}
				}
			}
		}
	}
}
