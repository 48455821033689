/*! palette-8.css */

// Bootstrap functions
@import "bootstrap/scss/functions";

// Bootstrap variable overrides
$link-decoration: underline;
$link-hover-decoration: none;
$font-family-sans-serif: "Libre Franklin", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
	"Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$breadcrumb-divider: quote(">");
$enable-negative-margins: true;

// Other global variables
$font-family-georgia: Georgia, Verdana, Arial, sans-serif;
$carnation: #f14e53;

// Bootstrap variables
@import "bootstrap/scss/variables";

// Palette colors
$prussian-blue: #002b58;
$tropical-blue: #c3daf9;
$heath: #561a12;
$hippie-blue: #6490B9;
$custom-colors: (
	"prussian-blue": $prussian-blue,
	"tropical-blue": $tropical-blue,
	"heath": $heath,
	"hippie-blue": $hippie-blue,
);
$theme-colors: map-merge($theme-colors, $custom-colors);

// Bootstrap colors
$body-bg: $white;
$body-color: $prussian-blue;
$link-color: $heath;

// CSS variables
$accent-color-1: $heath;
$accent-color-2: $hippie-blue;
$accent-color-3: $prussian-blue;
$background-color-light: $tropical-blue;
$background-color-dark: $prussian-blue;
$border-color-light: $tropical-blue;
$border-color-dark: $prussian-blue;
$icon-color-light: $tropical-blue;
$icon-color-dark: $prussian-blue;
$link-color-light: $tropical-blue;
$link-color-dark: $prussian-blue;
$text-color-light: $white;
$text-color-dark: $prussian-blue;

// Bootstrap theme colors
$primary: $prussian-blue;
$secondary: $tropical-blue;
$custom-theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
);
$theme-colors: map-merge($theme-colors, $custom-theme-colors);

// Layout

// Header
$header-bg-color: $body-bg;
$header-text-color: $body-color;
$header-link-color: $link-color;
$header-popout-search-icon-color: $body-color;
$header-links-bg-color: $background-color-light;
$header-links-link-color: $link-color;
$header-hide-visit-icon-color: $white;
$header-hide-visit-icon-bg-color: $danger;
$header-search-icon-color: $text-color-light;
$header-search-icon-bg-color: $icon-color-dark;
$header-search-border-color: $icon-color-dark;
$header-powered-by-link-color: $link-color-dark;
$header-lang-icon-color: $link-color-dark;
$header-lang-dropdown-bg-color: $background-color-light;
$header-lang-dropdown-link-color: $link-color-dark;

// Header navbar
$header-navbar-bg-color: $background-color-dark;
$header-navbar-link-color: $link-color-light;
$header-navbar-dropdown-bg-color: $background-color-light;
$header-navbar-dropdown-link-color: $link-color-dark;
$header-navbar-dropdown-link-hover-bg-color: tint-color($background-color-light, 75);
$header-navbar-mobile-bg-color: $background-color-light;
$header-navbar-mobile-link-color: $link-color-dark;

// Footer
$footer-bg-color: $background-color-dark;
$footer-border-top-color: $background-color-dark;
$footer-text-color: $text-color-light;
$footer-link-color: $link-color-light;
$footer-social-icon-color: $background-color-dark;
$footer-social-icon-bg-color: $icon-color-light;
$footer-social-icon-hover-color: $icon-color-light;
$footer-social-icon-hover-bg-color: $background-color-dark;
$footer-powered-by-link-color: $white;

// Back to top
$back-to-top-text-color: $text-color-light;
$back-to-top-bg-color: tint-color($background-color-dark, 10);
$back-to-top-border-color: tint-color($border-color-dark, 10);
$back-to-top-hover-bg-color: tint-color($background-color-dark, 20);
$back-to-top-hover-border-color: tint-color($border-color-dark, 20);

// Site map
$site-map-accordion-bg-color: $background-color-light;
$site-map-accordion-text-color: $text-color-dark;

// Pages/Apps

// Common - Filter page
$filter-bar-bg-color: $background-color-light;
$filter-bar-text-color: $text-color-dark;
$filter-bar-dropdown-bg-color: tint-color($background-color-light, 60);
$filter-bar-dropdown-hover-bg-color: tint-color($background-color-light, 90);
$selected-filters-bg-color: $background-color-light;
$result-item-border-color: $border-color-dark;
$result-item-alert-bg-color: $danger;
$result-item-alert-border-color: $border-color-dark;
$result-item-featured-bg-color: tint-color($background-color-light, 60);

// Common - Detail page
$item-detail-boolean-unchecked-bg-color: $gray-200;
$item-detail-boolean-unchecked-text-color: $gray-500;
$item-detail-boolean-checked-bg-color: $background-color-dark;
$item-detail-boolean-checked-text-color: $text-color-light;
$item-detail-lookup-label-bg-color: $background-color-dark;
$item-detail-lookup-label-text-color: $text-color-light;
$item-detail-lookup-value-bg-color: $background-color-light;
$item-detail-lookup-value-text-color: $text-color-dark;

// Calendar - Filter page
$event-item-date-bg-color: $background-color-light;
$event-item-cle-bg-color: $background-color-dark;
$event-item-cle-text-color: $text-color-light;

// Calendar - Small calendar
$event-calendar-border-color: $border-color-dark;
$event-calendar-header-bg-color: $background-color-light;
$event-calendar-date-range-bg-color: $gray-100;
$event-calendar-date-range-text-color: $text-color-dark;
$event-calendar-today-bg-color: $accent-color-1;
$event-calendar-today-text-color: $text-color-light;
$event-calendar-matching-event-bg-color: $tropical-blue;
$event-calendar-matching-event-text-color: $text-color-dark;
$event-calendar-non-matching-event-bg-color: tint-color($background-color-dark, 10);
$event-calendar-non-matching-event-text-color: $text-color-light;

// Calendar - Detail page
$event-detail-date-bg-color: $background-color-light;
$event-detail-date-text-color: $text-color-dark;
$event-detail-cle-bg-color: $background-color-dark;
$event-detail-cle-text-color: $text-color-light;

// Case - Detail page
$case-detail-review-text-color: $success;
$case-detail-accept-text-color: $danger;
$case-detail-close-text-color: $primary;
$case-detail-history-bg-color: $background-color-light;
$case-detail-next-date-bg-color: $background-color-light;
$case-detail-time-commitement-bg-color: $background-color-light;

// Directory (LH orgs) - Search page
$find-help-form-select-bg-color: $background-color-dark;
$find-help-form-select-text-color: $text-color-light;

// Directory (LH orgs) - Results page (no pages incuded)
$find-help-map-border-color: $border-color-dark;
$find-help-featured-item-border-color: $border-color-dark;
$find-help-featured-item-hover-border-color: $border-color-light;
$find-help-featured-item-try-color: $text-color-dark;
$find-help-featured-item-icon-color: $text-color-dark;
$find-help-featured-item-learn-bg-color: $background-color-dark;
$find-help-featured-item-learn-hover-bg-color: $background-color-light;
$find-help-featured-item-learn-link-color: $text-color-light;
$find-help-featured-item-learn-hover-link-color: $text-color-dark;
$find-help-summary-item-border-color: $border-color-dark;
$find-help-summary-item-divider-color: $text-color-dark;

// Directory (LH orgs) - Results page (pages incuded)
$find-help-section-heading-bg-color: $background-color-light;
$find-help-section-heading-text-color: $text-color-dark;
$find-help-topic-heading-bg-color: $background-color-dark;
$find-help-topic-heading-link-color: $link-color-light;
$find-help-topic-heading-icon-color: $text-color-light;
$find-help-topic-heading-text-color: $text-color-light;
$find-help-top-org-border-color: $border-color-dark;
$find-help-top-org-hover-border-color: $border-color-light;
$find-help-top-org-icon-color: $text-color-dark;
$find-help-top-org-learn-bg-color: $background-color-dark;
$find-help-top-org-learn-hover-bg-color: $background-color-light;
$find-help-top-org-learn-link-color: $text-color-light;
$find-help-top-org-learn-hover-link-color: $text-color-dark;
$find-help-summary-org-border-color: $border-color-dark;
$find-help-summary-org-learn-bg-color: $background-color-dark;
$find-help-summary-org-learn-link-color: $text-color-light;

// Directory (LH orgs) - Detail page
$find-help-detail-divider-color: $border-color-dark;
$find-help-detail-map-border-color: $border-color-dark;
$find-help-detail-map-bg-color: $background-color-light;

// Library - Filter page/Folder items
$library-item-featured-bg-color: $background-color-light;
$library-item-featured-text-color: $text-color-dark;
$library-item-recent-bg-color: $background-color-dark;
$library-item-recent-text-color: $text-color-light;

// Library - Folder page
$library-folder-selected-bg-color: $background-color-light;

// Library - Detail page
$library-detail-attachments-bg-color: $background-color-light;

// News - Detail page
$article-detail-alert-bg-color: $danger;
$article-detail-alert-text-color: $white;

// Opps guide - Filter page
$opps-guide-search-dropdown-bg-color: $background-color-light;
$opps-guide-search-dropdown-hover-bg-color: tint-color($background-color-light, 60);
$opps-guide-national-badge-bg-color: $background-color-dark;
$opps-guide-national-badge-text-color: $text-color-light;

// Opps guide - Detail page
$org-detail-correction-bg-color: $background-color-light;
$org-detail-download-bg-color: $background-color-light;

// Roster page
$roster-pagination-bg-color: $background-color-light;
$roster-pagination-text-color: $text-color-dark;

// Flex page
$flex-page-nav-bg-color: $background-color-light;
$flex-page-tab-bg-color: $background-color-dark;
$flex-page-tab-text-color: $text-color-light;
$flex-page-tab-hover-bg-color: $background-color-light;
$flex-page-tab-hover-text-color: $text-color-dark;
$flex-page-tab-content-border-color: $border-color-light;
$flex-page-item-header-bg-color: $background-color-light;
$flex-page-item-header-hover-bg-color: $background-color-dark;
$flex-page-item-header-text-color: $text-color-dark;
$flex-page-item-header-hover-text-color: $text-color-light;
$flex-page-item-header-border-color: $border-color-dark;

// Listserv page
$listserv-page-header-bg-color: $background-color-light;
$listserv-page-header-text-color: $text-color-dark;
$listserv-page-divider-color: $border-color-light;
$listserv-page-sidebar-bg-color: $gray-100;

// Widgets

// Main Header Bootstrap Carousel
$main-header-carousel-bg-color: $background-color-light;

// Main Header Button Group
$main-header-button-group-bg-color-1: $accent-color-1;
$main-header-button-group-hover-bg-color-1: tint-color($accent-color-1, 20);
$main-header-button-group-text-color-1: $text-color-light;
$main-header-button-group-bg-color-2: $accent-color-2;
$main-header-button-group-hover-bg-color-2: tint-color($accent-color-2, 20);
$main-header-button-group-text-color-2: $text-color-light;
$main-header-button-group-bg-color-3: $accent-color-3;
$main-header-button-group-hover-bg-color-3: tint-color($accent-color-3, 20);
$main-header-button-group-text-color-3: $text-color-light;

// Bootstrap Album - Default Styles
$album-card-border-color: $border-color-dark;
$album-card-header-bg-color: $background-color-light;
$album-card-header-text-color: $text-color-dark;
$album-card-footer-bg-color: $background-color-light;
$album-card-footer-text-color: $text-color-dark;

// Bootstrap Album - Topic Card Album
$topic-card-album-card-border-color-1: $accent-color-1;
$topic-card-album-card-header-bg-color-1: $accent-color-1;
$topic-card-album-card-header-text-color-1: $text-color-light;
$topic-card-album-card-header-link-color-1: $text-color-light;
$topic-card-album-card-border-color-2: $accent-color-2;
$topic-card-album-card-header-bg-color-2: $accent-color-2;
$topic-card-album-card-header-text-color-2: $text-color-light;
$topic-card-album-card-header-link-color-2: $text-color-light;
$topic-card-album-card-border-color-3: $accent-color-3;
$topic-card-album-card-header-bg-color-3: $accent-color-3;
$topic-card-album-card-header-text-color-3: $text-color-light;
$topic-card-album-card-header-link-color-3: $text-color-light;

// Bootstrap Album - Topic Icon Album
$topic-icon-album-icon-bg-color: $icon-color-dark;
$topic-icon-album-icon-text-color: $text-color-light;

// Bootstrap Accordion
$accordion-item-header-bg-color: $background-color-light;
$accordion-item-header-hover-bg-color: $background-color-dark;
$accordion-item-header-text-color: $text-color-dark;
$accordion-item-header-hover-text-color: $text-color-light;
$accordion-item-header-border-color: $border-color-dark;

// Bootstrap required
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// Bootstrap optional
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
//@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
//@import "bootstrap/scss/spinners";
//@import "bootstrap/scss/offcanvas";
//@import "bootstrap/scss/placeholders";

// Bootstrap helpers
@import "bootstrap/scss/helpers";

// Bootstrap utilities api
@import "bootstrap/scss/utilities/api";

// NProgress
@import "nprogress/nprogress";

// Icon set
@import "../../pbn-icons/scss/app";

// Site styles
@import "abstracts/all";
@import "base/all";
@import "components/all";
@import "layout/all";
@import "pages/all";
