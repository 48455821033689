// Filter view
.libraryApp {
	#library-controls {
		//margin-top: -2rem;
		margin-bottom: 0.5rem;
		&::before {
			content: "<";
			margin-right: 0.25rem;
			display: inline-block;
		}
	}
	.rcresource {
		.content-alert-message {
			background-color: $library-item-featured-bg-color;
			color: $library-item-featured-text-color;
			&::before {
				margin-right: 0;
				content: "";
			}
		}
		.recent {
			background-color: $library-item-recent-bg-color;
			color: $library-item-recent-text-color;
			font-weight: 600;
			padding: 0.25rem 0.5rem;
		}
		.content-alert-message,
		.recent {
			+ .content-info {
				border-top: none;
				background-color: $result-item-featured-bg-color;
				margin-bottom: 1rem;
			}
		}
		.content-info {
			padding: 1rem;
			.resource-body {
				position: relative;
				.icon-file,
				.icon-url {
					&::before {
						font-family: "pbn-icons";
						font-size: 1.5rem;
						float: left;
						margin: 0.1rem 0.75rem 1rem 0;
					}
				}
				.icon-file {
					&::before {
						content: $pbn-icon-text;
					}
				}
				.icon-url {
					&::before {
						content: $pbn-icon-globe;
					}
				}
			}
		}
	}
}

// Folder view
#library-display {
	@extend .row;
	margin-top: 1rem;
	#root-folder {
		@extend .col-12, .col-md-4, .order-1;
		.selected-folder {
			background-color: $library-folder-selected-bg-color;
			.folder-name {
				font-weight: 600;
			}
		}
		.collapsed-folder,
		.expanded-folder {
			cursor: pointer;
			display: inline-block;
			padding: 0 0.5rem;
			&::before {
				font-family: "pbn-icons";
				margin-right: 0.5rem;
				display: inline-block;
			}
			.folder-name {
				display: inline-block;
			}
			.link {
				.shortcut {
					&::before {
						font-family: "pbn-icons";
						margin-left: 0.15rem;
						display: inline-block;
						content: $pbn-icon-link;
						font-size: 0.75rem;
					}
				}
				.editable {
					font-size: 0px;
					&::after {
						font-family: "pbn-icons";
						margin-left: 0.5rem;
						display: inline-block;
						content: $pbn-icon-edit;
						font-size: 0.75rem;
					}
				}
			}
		}
		.collapsed-folder {
			&::before {
				content: $pbn-icon-folder-closed;
			}
		}
		.expanded-folder {
			&::before {
				content: $pbn-icon-folder-open;
			}
		}
		.folder-list {
			folderapp {
				> .folder-list {
					padding-left: 1rem;
					overflow: hidden;
				}
			}
		}
	}
	folder-contents {
		@extend .col-12, .col-md-8, .order-2;
		.folder-breadcrumbs {
			display: none;
		}
		.folder-info {
			.folder-stats {
				font-weight: 600;
				margin-bottom: 1rem;
			}
			.library-resource-list {
				@extend .list-unstyled;
				li {
					.featured {
						background-color: $library-item-featured-bg-color;
						color: $library-item-featured-text-color;
						font-weight: 600;
						padding: 0.25rem 0.5rem;
						border-top: 1px solid $result-item-border-color;
						text-transform: uppercase;
					}
					.recent {
						background-color: $library-item-recent-bg-color;
						color: $library-item-recent-text-color;
						font-weight: 600;
						padding: 0.25rem 0.5rem 0.25rem 0.5rem;
						+ div:not(.priority-item) {
							border-top: none;
							background-color: $result-item-featured-bg-color;
							margin-bottom: 1rem;
						}
					}
					> div {
						&:last-child {
							border-top: 1px solid $result-item-border-color;
							padding: 1rem 1rem 1rem 3rem;
							position: relative;
							.icon-file,
							.icon-url {
								&::before {
									font-family: "pbn-icons";
									font-size: 1.5rem;
									position: absolute;
									top: 1rem;
									left: 1rem;
								}
							}
							.icon-file {
								&::before {
									content: $pbn-icon-text;
								}
							}
							.icon-url {
								&::before {
									content: $pbn-icon-globe;
								}
							}
							.content-title {
								font-size: 1.25rem;
								font-weight: 600;
							}
							&.priority-item {
								border-top: none;
								background-color: $result-item-featured-bg-color;
								margin-bottom: 1rem;
							}
						}
					}
				}
			}
		}
	}
}

// Detail view
#content {
	.resource {
		.content-detail {
			.props {
				&.attachments {
					background-color: $library-detail-attachments-bg-color;
					padding: 0.25rem 0.5rem;
					font-weight: 600;
					a {
						margin-left: 0.5rem;
						font-weight: 400;
						&::before {
							font-family: "pbn-icons";
							display: inline-block;
							margin-right: 0.25rem;
						}
						&.external {
							&::before {
								content: $pbn-icon-external-link;
							}
						}
						&.JPG,
						&.JPEG,
						&.PNG,
						&.GIF,
						&.BMP,
						&.SVG {
							&::before {
								content: $pbn-icon-image;
							}
						}
						&.PDF {
							&::before {
								content: $pbn-icon-pdf;
							}
						}
						&.DOC,
						&.DOCX {
							&::before {
								content: $pbn-icon-word;
							}
						}
						&.HTM,
						&.HTML {
							&::before {
								content: $pbn-icon-html;
							}
						}
						&.XLS,
						&.XLSX {
							&::before {
								content: $pbn-icon-excel;
							}
						}
						&.PPT,
						&.PPTX {
							&::before {
								content: $pbn-icon-powerpoint;
							}
						}
						&.TXT {
							&::before {
								content: $pbn-icon-text;
							}
						}
						&.ZIP {
							&::before {
								content: $pbn-icon-zip;
							}
						}
					}
				}
				.organization,
				.author,
				.creation_date,
				.submission_date {
					font-weight: 600;
					strong {
						font-weight: 400;
					}
				}
			}
		}
	}
}
