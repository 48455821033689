.find-legal-help-header {
	+ .btn {
		@extend .btn-primary;
	}
}

.find-legal-help-summary {
	.find-legal-help-form {
		select {
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
			&:focus {
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
			}
		}
		select,
		option,
		optgroup {
			background-color: $find-help-form-select-bg-color !important;
			color: $find-help-form-select-text-color !important;
		}
		.btn {
			@extend .btn-primary;
		}
	}
}

.find-legal-help-results {
	.btn {
		@extend .btn-primary;
	}
	.find-legal-help-org-featured {
		border-color: $find-help-featured-item-border-color !important;
		cursor: pointer;
		transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
			0.3s box-shadow,
			0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
		.try-this-first {
			color: $find-help-featured-item-try-color;
			font-weight: 600;
			margin-bottom: 0.75rem !important;
			&::before {
				font-family: "pbn-icons";
				content: $pbn-icon-checkmark;
				margin-right: 0.25rem;
			}
		}
		a {
			font-weight: 600 !important;
		}
		.phone {
			font-weight: 400 !important;
			margin-top: 1rem;
			margin-bottom: 1rem !important;
		}
		p.learn-more {
			display: none;
		}
		div.learn-more {
			display: block !important;
			margin: 0 -1rem -1rem -1rem;
			padding: 1rem;
			background-color: $find-help-featured-item-learn-bg-color !important;
			a {
				color: $find-help-featured-item-learn-link-color;
				text-decoration: none;
				font-weight: 600;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.icon-list {
			li {
				.phone-icon,
				.email-icon,
				.website-icon,
				.map-icon {
					font-family: "pbn-icons";
					font-size: 1.5rem;
					display: inline-block;
					font-style: normal;
					color: $find-help-featured-item-icon-color;
				}
				.phone-icon {
					&::before {
						content: $pbn-icon-call;
					}
				}
				.email-icon {
					&::before {
						content: $pbn-icon-email;
					}
				}
				.website-icon {
					&::before {
						content: $pbn-icon-globe;
					}
				}
				.map-icon {
					&::before {
						content: $pbn-icon-map;
					}
				}
				&:last-child {
					display: inline-block !important;
				}
			}
		}
		.address {
			display: block !important;
			li {
				line-height: 1.25;
			}
		}
		&:hover {
			border-color: $find-help-featured-item-hover-border-color !important;
			transform: scale(1.01);
			box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12),
				0 4px 8px rgba(0, 0, 0, 0.06);
			div.learn-more {
				background-color: $find-help-featured-item-learn-hover-bg-color !important;
				a {
					color: $find-help-featured-item-learn-hover-link-color;
				}
			}
		}
	}
	.find-legal-help-org-summary {
		border-color: $find-help-summary-item-border-color !important;
		.list-inline-item {
			.fa-phone::before {
				font-family: "pbn-icons";
				display: inline-block;
				content: $pbn-icon-call;
			}
			&::after {
				content: "|";
				color: $find-help-summary-item-divider-color;
				font-size: 90%;
				margin-left: 0.25rem;
			}
			&:last-child::after {
				content: "";
			}
		}
	}
	.section-heading {
		background-color: $find-help-section-heading-bg-color;
		color: $find-help-section-heading-text-color;
		padding: 0.5rem;
		font-weight: 700;
		font-size: 1rem;
	}
	.find-legal-help-org {
		border: 1px solid $find-help-summary-org-border-color;
		h4 {
			font-weight: 600;
			padding: 1rem 1rem 0 1rem;
		}
		ul {
			padding: 0.5rem 1rem 1rem 1rem;
			margin: 0;
			a {
				font-weight: 600;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
		}
		.address {
			padding-bottom: 0;
		}
		.learn-more {
			background-color: $find-help-summary-org-learn-bg-color;
			a {
				color: $find-help-summary-org-learn-link-color;
				text-decoration: none;
				font-weight: 600;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		&.top-org {
			border: 1px solid $find-help-top-org-border-color;
			cursor: pointer;
			transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
				0.3s box-shadow,
				0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
			.icon-list {
				padding-right: 0;
				a {
					&::before {
						font-family: "pbn-icons";
						display: inline-block;
						font-size: 1.5rem;
						color: $find-help-top-org-icon-color;
					}
					&.phone-icon-wrapper {
						&::before {
							content: $pbn-icon-call;
						}
					}
					&.email-icon-wrapper {
						&::before {
							content: $pbn-icon-email;
						}
					}
					&.website-icon-wrapper {
						&::before {
							content: $pbn-icon-globe;
						}
					}
					svg {
						display: none;
					}
				}
			}
			.learn-more {
				background-color: $find-help-top-org-learn-bg-color;
				a {
					color: $find-help-top-org-learn-link-color;
				}
			}
			&:hover {
				border-color: $find-help-top-org-hover-border-color;
				transform: scale(1.01);
				box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12),
					0 4px 8px rgba(0, 0, 0, 0.06);
				.learn-more {
					background-color: $find-help-top-org-learn-hover-bg-color;
					text-decoration: underline;
					a {
						color: $find-help-top-org-learn-hover-link-color;
					}
				}
			}
		}
	}
	.topic-heading {
		background-color: $find-help-topic-heading-bg-color;
		border-radius: 0.5rem;
		transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
			0.3s box-shadow,
			0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
		h4 {
			font-size: 1.5rem;
			font-weight: 600;
			margin-bottom: 0;
			a {
				color: $find-help-topic-heading-link-color;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		span {
			font-size: 0.875rem;
			font-weight: 700;
			color: $find-help-topic-heading-text-color;
		}
		&:hover {
			transform: scale(1.01);
			box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12),
				0 4px 8px rgba(0, 0, 0, 0.06);
		}
		.topic-icon {
			&::before {
				font-family: "pbn-icons";
				font-size: 2.75rem;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				line-height: inherit;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				display: block;
				color: $find-help-topic-heading-icon-color;
			}
			&.rights {
				&::before {
					content: $pbn-icon-shield;
				}
			}
			&.legalforms {
				&::before {
					content: $pbn-icon-text;
				}
			}
		}
	}
	#google-map {
		border: 1px solid $find-help-map-border-color;
		height: 400px;
		width: 100%;
	}
}

.find-legal-help-detail {
	.inner-left-column {
		@include media-breakpoint-up(md) {
			border-right: 1px solid $find-help-detail-divider-color;
			margin-bottom: 0.5rem;
		}
		.fa-phone,
		.fa-envelope-open,
		.fa-globe-americas,
		.fa-map-marker-alt {
			&::before {
				font-family: "pbn-icons";
				display: inline-block;
				font-style: normal;
			}
		}
		.fa-phone {
			&::before {
				content: $pbn-icon-call;
			}
		}
		.fa-envelope-open {
			&::before {
				content: $pbn-icon-email;
			}
		}
		.fa-globe-americas {
			&::before {
				content: $pbn-icon-globe;
			}
		}
		.fa-map-marker-alt {
			&::before {
				content: $pbn-icon-map-pin;
			}
		}
	}
	.google-map-wrapper {
		border-color: $find-help-detail-map-border-color !important;
		> div {
			border-color: $find-help-detail-map-border-color !important;
			background-color: $find-help-detail-map-bg-color;
		}
		.google-map {
			height: 300px;
			width: 100%;
			overflow: hidden;
		}
	}
}
