#back-to-top {
	position: fixed;
	bottom: 40px;
	right: 20px;
	color: $back-to-top-text-color;
	background-color: $back-to-top-bg-color;
	border-color: $back-to-top-border-color;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
	&:hover,
	&:active {
		color: $text-color-light;
		background-color: $back-to-top-hover-bg-color;
		border-color: $back-to-top-hover-border-color;
	}
	span {
		font-size: 0.875rem;
	}
}

.success-msg,
#site-message {
	@extend .alert, .alert-success;
}

#frm-error {
	@extend .alert, .alert-warning;
	h4,
	dl {
		margin-left: 3rem;
		padding: 0;
	}
	dl {
		margin-bottom: 0;
	}
}

.hvr-grow {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-property: transform;
	transition-property: transform;
	&:hover,
	&:focus,
	&:active {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}
}

.hvr-shrink {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-property: transform;
	transition-property: transform;
	&:hover,
	&:focus,
	&:active {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}
}
