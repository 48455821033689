.btn-circle {
	width: 50px;
	height: 50px;
	min-width: 50px;
	text-align: center;
	padding: 0;
	border-radius: 50%;
}

.btn-sm.btn-circle {
	width: 35px;
	height: 35px;
	min-width: 35px;
	line-height: 35px;
	font-size: 0.9rem;
}

.btn-lg.btn-circle {
	width: 55px;
	height: 55px;
	min-width: 55px;
	line-height: 55px;
	font-size: 1.1rem;
}
