form {
	&.main,
	&.primary {
		fieldset {
			background-color: $gray-100;
		}
		input[type="submit"],
		input[type="button"] {
			@extend .btn, .btn-primary;
			padding: 0.375rem 0.75rem !important;
		}
	}
}
