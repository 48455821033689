.listserv-header {
	.back {
		display: none;
	}
	.list-inline {
		li {
			margin-top: 1rem;
			a {
				@extend .icon-link;
				i,
				br {
					display: none;
				}
				&:has(.fa-edit) {
					&::before {
						content: $pbn-icon-edit;
					}
				}
				&:has(.fa-users) {
					&::before {
						content: $pbn-icon-members;
					}
				}
				&:has(.fa-plus-circle) {
					&::before {
						content: $pbn-icon-add;
					}
				}
				&:has(.fa-tags) {
					&::before {
						content: $pbn-icon-tags;
					}
				}
			}
		}
	}
}
.lists-header,
.list-archive-header,
.listserv-message-header {
	background-color: $listserv-page-header-bg-color;
	color: $listserv-page-header-text-color;
	font-weight: 500;
}

.list-description,
.list-message-body,
.listserv-message,
.listserv-sidebar {
	.border-bottom,
	.border-top {
		border-color: $listserv-page-divider-color !important;
	}
}
.list-details,
.list-message-details {
	.col-12,
	.col-6 {
		font-weight: 600;
	}
	.col-3 a {
		text-decoration: none;
	}
}
.list-sidebar-overview,
.list-sidebar-message,
.list-sidebar-subscribe {
	background-color: $listserv-page-sidebar-bg-color;
}
