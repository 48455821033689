.bootstrap-album {
	.card {
		@extend .rounded-0;
		border-color: $album-card-border-color;
		.card-header,
		.card-footer {
			@extend .rounded-0;
		}
		.card-header {
			color: $album-card-header-text-color;
			background-color: $album-card-header-bg-color;
			border-color: shade-color($album-card-header-bg-color, 20);
		}
		.card-footer {
			color: $album-card-footer-text-color;
			background-color: $album-card-footer-bg-color;
			border-color: shade-color($album-card-footer-bg-color, 20);
		}
	}
	&.topic-card-album {
		.card {
			cursor: pointer;
			transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
				0.3s box-shadow,
				0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
			&:hover {
				transform: scale(1.01);
				box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12),
					0 4px 8px rgba(0, 0, 0, 0.06);
				a {
					text-decoration: none;
				}
			}
		}
		.row:nth-child(even) .card-column:nth-child(3n-2),
		.row:nth-child(odd) .card-column:nth-child(3n) {
			.card {
				border-color: $topic-card-album-card-border-color-1;
				.card-header {
					color: $topic-card-album-card-header-text-color-1;
					background-color: $topic-card-album-card-header-bg-color-1;
					border-color: shade-color(
						$topic-card-album-card-header-bg-color-1,
						20
					);
					a {
						color: $topic-card-album-card-header-link-color-1;
					}
				}
			}
		}
		.row:nth-child(even) .card-column:nth-child(3n-1),
		.row:nth-child(odd) .card-column:nth-child(3n-2) {
			.card {
				border-color: $topic-card-album-card-border-color-2;
				.card-header {
					color: $topic-card-album-card-header-text-color-2;
					background-color: $topic-card-album-card-header-bg-color-2;
					border-color: shade-color(
						$topic-card-album-card-header-bg-color-2,
						20
					);
					a {
						color: $topic-card-album-card-header-link-color-2;
					}
				}
			}
		}
		.row:nth-child(even) .card-column:nth-child(3n),
		.row:nth-child(odd) .card-column:nth-child(3n-1) {
			.card {
				border-color: $topic-card-album-card-border-color-3;
				.card-header {
					color: $topic-card-album-card-header-text-color-3;
					background-color: $topic-card-album-card-header-bg-color-3;
					border-color: shade-color(
						$topic-card-album-card-header-bg-color-3,
						20
					);
					a {
						color: $topic-card-album-card-header-link-color-3;
					}
				}
			}
		}
	}
	&.topic-icon-album {
		.card {
			border: none;
			@extend .d-flex, .flex-row, .align-items-start;
			.card-icon {
				@extend .btn-circle;
				background-color: $topic-icon-album-icon-bg-color;
				color: $topic-icon-album-icon-text-color;
				margin-right: 0.75rem;
				line-height: 50px;
				span {
					font-size: 24px;
				}
			}
			.card-body {
				padding: 0;
			}
		}
	}
}
