.flex-page-header {
	.flex-page-return {
		display: none;
	}
}
.flex-page-home-wrapper {
	.flex-page-home-landing {
		margin-bottom: 1.5rem;
		.content-summary {
			&:has(.expandable) {
				.expandable {
					background-color: $flex-page-item-header-bg-color;
					color: $flex-page-item-header-text-color;
					border-bottom: 1px solid $flex-page-item-header-border-color;
					font-size: 1rem;
					font-weight: 400;
					padding: 1rem;
					margin-bottom: 0;
					position: relative;
					&::after {
						position: absolute;
						right: 1.25rem;
						width: 24px;
						height: 24px;
						content: "";
						transition: transform 0.2s ease-in-out;
						background-repeat: no-repeat;
						background-image: escape-svg(
							url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$flex-page-item-header-text-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>")
						);
					}
					&:hover {
						background-color: $flex-page-item-header-hover-bg-color;
						color: $flex-page-item-header-hover-text-color;
						&::after {
							background-image: escape-svg(
								url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$flex-page-item-header-hover-text-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>")
							);
						}
					}
					&.ExpandableBGColorOpen {
						&::after {
							transform: rotate(-180deg);
						}
					}
				}
				.item-wrapper {
					padding: 1rem 1rem 0 1rem;
				}
			}
			.item-wrapper {
				.props {
					@extend .list-unstyled;
					margin-bottom: 0.5rem;
					li {
						font-weight: 600;
						strong {
							margin-left: 0.25rem;
							font-weight: 400;
						}
					}
				}
			}
		}
		&:not(.no-nav) {
			@extend .col-12, .col-md-8;
		}
	}
}
.flex-page-tabbed,
.flex-page-tabbed-horizontal {
	.tab-list-wrapper {
		.list-group {
			font-weight: 600;
			.list-group-item {
				background-color: $flex-page-tab-bg-color;
				border: 0;
				color: $flex-page-tab-text-color;
				border-radius: 0;
				&.active,
				&:hover {
					color: $flex-page-tab-hover-text-color;
					background-color: $flex-page-tab-hover-bg-color;
				}
			}
		}
	}
	.tab-content-wrapper {
		//padding: 0;
		.tab-content {
			border: 1px solid $flex-page-tab-content-border-color;
			padding: 1rem;
			height: 100%;
		}
	}
}
.flex-page-tabbed {
	.list-group-item {
		&:not(:last-child) {
			margin-bottom: 0.25rem;
		}
	}
	@include media-breakpoint-up(sm) {
		.tab-list-wrapper {
			padding-right: 0;
		}
		.list-group-item {
			text-align: right;
		}
		.tab-content-wrapper {
			padding: 0;
			border-left: 5px solid $flex-page-tab-content-border-color;
		}
	}
}
.flex-page-tabbed-horizontal {
	.list-group-item {
		&:not(:last-child) {
			margin-bottom: 0.25rem;
		}
	}
	@include media-breakpoint-up(md) {
		.list-group-item {
			text-align: center;
			&:not(:last-child) {
				margin-bottom: 0;
				margin-right: 0.25rem;
			}
		}
		.tab-content-wrapper {
			border-top: 5px solid $flex-page-tab-content-border-color;
		}
	}
}
.flex-page-home-nav,
.flex-page-detail-nav {
	@extend .col-12, .col-md-4;
	ul {
		@extend .list-unstyled;
		background-color: $flex-page-nav-bg-color;
		margin-top: 1rem;
		padding: 1rem;
		li {
			&.current {
				font-weight: 400;
			}
			&.active {
				font-weight: 600;
			}
		}
	}
}
.flex-page-detail-wrapper {
	@extend .row;
	.flex-page-detail-landing {
		@extend .col-12, .col-md-8;
	}
}
