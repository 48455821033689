@font-face {
	font-family: "pbn-icons";
	src: url("../../pbn-icons/fonts/pbn-icons.woff2") format("woff2"),
		url("../../pbn-icons/fonts/pbn-icons.ttf") format("truetype"),
		url("../../pbn-icons/fonts/pbn-icons.woff") format("woff"),
		url("../../pbn-icons/fonts/pbn-icons.svg") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}
