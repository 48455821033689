.related-content {
	.text-search {
		margin-bottom: 0.75rem;
		position: relative;
		.results {
			background-color: $opps-guide-search-dropdown-bg-color;
			position: absolute;
			z-index: 500;
			max-height: 250px;
			overflow-y: auto;
			overflow-x: hidden;
			font-size: 0.9rem;
			line-height: 1.25rem;
			li {
				cursor: pointer;
				padding: 0.4rem 1rem 0.4rem 1rem;
				position: relative;
				&:hover {
					background-color: $opps-guide-search-dropdown-hover-bg-color;
				}
			}
		}
	}
	.content-info {
		.content-title {
			&.is-national::after {
				border-radius: 5px;
				padding: 0.25rem;
				margin-left: 0.5rem;
				background-color: $opps-guide-national-badge-bg-color;
				color: $opps-guide-national-badge-text-color;
				font-size: 0.55rem;
				text-transform: uppercase;
				text-decoration: none;
				content: "National";
			}
		}
	}
}

#content {
	.oppsguide {
		// oppsguide breadcrumbs
		.tool-options {
			display: none;
		}
	}
	.oppsguide .organization {
		.content-detail {
			.right-column {
				.accent {
					font-size: 0.8rem;
					padding: 0.25rem 0.5rem;
					border-radius: 0;
					&.alert {
						background-color: $org-detail-correction-bg-color;
						&::before {
							font-family: "pbn-icons";
							content: $pbn-icon-info;
							margin-right: 0.25rem;
						}
					}
					&.vcard-dl {
						background-color: $org-detail-download-bg-color;
						margin-top: 1rem;
						margin-bottom: 1rem;
						&::before {
							font-family: "pbn-icons";
							content: $pbn-icon-download;
							margin-right: 0.25rem;
						}
					}
				}
				.volunteer-request {
					margin-bottom: 1rem;
					a {
						@extend .btn-primary;
					}
				}
				.org-address {
					li {
						font-weight: 600;
						strong {
							font-weight: 400;
						}
						.map {
							width: fit-content;
							font-weight: 400;
							margin-bottom: 1rem;
							em {
								display: none;
							}
						}
					}
				}
				.contact-box {
					div {
						font-weight: 400;
					}
				}
			}
		}
	}
}
