.calendarApp {
	// Hide full calendar links for now
	.hideInMobile.link,
	.hideInDesktop.link {
		visibility: hidden;
		height: 0px;
		width: 0px;
	}
	.small-calendar {
		position: relative;
		@include media-breakpoint-up(md) {
			float: right;
			margin-left: 2em;
			width: 300px;
		}
		.date-control {
			display: flex;
			justify-content: space-between;
			padding: 0.25rem;
			align-items: center;
			.rcprev,
			.rcnext {
				@extend .btn, .btn-sm;
				font-size: 1.25rem;
			}
			.month {
				font-weight: 600;
			}
			@include media-breakpoint-up(md) {
				border-color: $event-calendar-border-color;
				border-style: solid solid none solid;
				border-width: 1px 1px 0 1px;
			}
		}
		p-schedule {
			position: absolute;
			visibility: hidden;
			height: 0px !important;
			left: -100px;
			top: 0px;
			.fc-toolbar {
				display: none;
			}
			.fc-head {
				.ui-widget-header {
					border-color: $event-calendar-border-color;
					background: $event-calendar-header-bg-color;
					text-transform: uppercase;
					font-size: 0.875rem;
					font-weight: 600;
				}
			}
			.fc-body {
				.ui-widget-content {
					border-color: $event-calendar-border-color;
					.fc-row {
						min-height: auto;
						.fc-content-skeleton {
							padding-bottom: 0;
							tbody,
							.fc-event-container {
								display: none;
							}
						}
						td {
							font-size: 0.75rem;
							padding-right: 0.25rem;
							cursor: pointer;
							padding-bottom: 1rem;
						}
						.fc-today,
						.fc-day-top {
							border-color: $event-calendar-border-color !important;
							border-width: 1px;
							border-style: solid;
							border-bottom: none;
						}
						.fc-today,
						.fc-today.rcHasFocus {
							background-color: $event-calendar-today-bg-color;
							color: $event-calendar-today-text-color;
						}
						.rcHasFocus {
							background-color: $event-calendar-date-range-bg-color;
							color: $event-calendar-date-range-text-color;
						}
						.rcHasEvent {
							background-color: $event-calendar-non-matching-event-bg-color;
							color: $event-calendar-non-matching-event-text-color;
						}
						.rcHasEvent.rcHasFocus {
							background-color: $event-calendar-matching-event-bg-color;
							color: $event-calendar-matching-event-text-color;
						}
					}
				}
			}
			@include media-breakpoint-up(md) {
				position: relative;
				visibility: visible;
				height: auto !important;
				left: auto;
			}
		}
		.colorKey {
			display: none;
			margin-top: 1rem;
			margin-bottom: 1rem;
			font-size: 0.9rem;
			.key {
				margin-bottom: 0.15rem;
				&::before {
					border: 1px solid #000;
					content: " ";
					margin-right: 0.25rem;
					padding: 0;
					display: inline-block;
					width: 14px;
					height: 14px;
				}
				&.keyToday {
					&::before {
						background-color: $event-calendar-today-bg-color;
					}
				}
				&.keyHasFocus {
					&::before {
						background-color: $event-calendar-date-range-bg-color;
					}
				}
				&.keyHasEvent {
					&::before {
						background-color: $event-calendar-non-matching-event-bg-color;
					}
				}
				&.keyHasEvent.keyHasFocus {
					&::before {
						background-color: $event-calendar-matching-event-bg-color;
					}
				}
			}
			@include media-breakpoint-up(md) {
				display: block;
			}
		}
	}
	.rcevent {
		border-top: 1px solid $result-item-border-color;
		padding: 1rem 0 1rem 0;
		display: flex;
		.date {
			background-color: $event-item-date-bg-color;
			width: 50px;
			flex: 0 0 50px;
			text-align: center;
			font-size: 0.9rem;
			border-radius: 7px 0 0 7px;
			padding: 0.75rem 0.5rem;
			height: fit-content;
			.date-number {
				display: block;
				font-weight: 600;
			}
		}
		.content-info {
			margin-left: 1rem;
			border-top: none;
			padding: 0;
			.event-info {
				margin-top: 0.25rem;
			}
			.hasCle {
				width: 45px;
				height: 45px;
				border-radius: 50%;
				background-color: $event-item-cle-bg-color;
				color: $event-item-cle-text-color;
				text-align: center;
				text-transform: uppercase;
				font-size: 0.7rem;
				line-height: 0.7rem;
				padding-top: 0.55rem;
				float: left;
				margin: 0.5rem 0.5rem 0.5rem 0;
				transform: scale(0.85);
			}
			.time {
				font-weight: 600;
			}
		}
	}
}

#content {
	.event {
		.content-detail {
			.description {
				.props {
					li {
						&.event-date h4 {
							background-color: $event-detail-date-bg-color;
							color: $event-detail-date-text-color;
							padding: 0.5rem 0.5rem;
							width: fit-content;
							font-weight: 600;
							font-size: 1.25rem;
							abbr {
								display: inline-block;
							}
							br {
								display: none;
							}
						}
						&.cleCredit {
							abbr {
								display: inline-block;
								width: 45px;
								height: 45px;
								border-radius: 50%;
								background-color: $event-detail-cle-bg-color;
								color: $event-detail-cle-text-color;
								text-align: center;
								text-transform: uppercase;
								font-size: 0.7rem;
								line-height: 0.7rem;
								padding-top: 0.55rem;
								transform: scale(0.85);
							}
						}
						&.org {
							font-weight: 600;
							strong {
								font-weight: 400;
							}
						}
						.eventlocationname {
							font-weight: 600;
						}
						.map {
							width: fit-content;
							em {
								display: none;
							}
						}
						&.download-link {
							margin-top: 0.5rem;
							a {
								&::before {
									font-family: "pbn-icons";
									content: $pbn-icon-download;
									margin-right: 0.25rem;
									display: inline-block;
								}
							}
						}
						abbr:not(.region) {
							font-weight: 600;
							+ strong {
								font-weight: 400;
							}
						}
						&.registration {
							margin-top: 1rem;
							a {
								text-transform: uppercase;
								@extend .btn, .btn-primary;
							}
						}
						&.attachments {
							margin-top: 0.5rem;
							font-weight: 600;
							a {
								margin-left: 0.5rem;
								font-weight: 400;
							}
						}
					}
				}
			}
		}
	}
}
