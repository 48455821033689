.footer {
	background-color: $footer-bg-color;
	border-top: 2px solid $footer-border-top-color;
	color: $footer-text-color;
	padding: 2rem 0 2rem 0;
	@include media-breakpoint-up(md) {
		padding: 3.5rem 0 3.5rem 0;
	}
	a {
		color: $footer-link-color;
	}
	.footer-links,
	.copyright-links {
		.list-inline-item:not(:last-child)::after {
			content: "";
			@include media-breakpoint-up(md) {
				content: "|";
				padding-left: 0.45rem;
				font-weight: 700;
			}
		}
	}
	.copyright-links,
	.copyright-message {
		font-size: 0.875rem;
	}
	.partner-links {
		h4 {
			font-size: 1rem;
		}
		img {
			max-height: 30px;
			margin-bottom: 0.5rem;
			@include media-breakpoint-up(lg) {
				max-height: 50px;
			}
		}
	}
	.social-links {
		font-size: 1.25rem;
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
		a {
			width: 2rem;
			height: 2rem;
			margin-bottom: 0.25rem;
			background-color: $footer-social-icon-bg-color;
			color: $footer-social-icon-color;
			text-decoration: none;
			border-radius: 50%;
			text-align: center;
			display: inline-block;
			vertical-align: top;
			transition: background 0.3s ease-in-out, opacity 0.3s ease-in-out;
			&:hover {
				background-color: $footer-social-icon-hover-bg-color;
				color: $footer-social-icon-hover-color;
			}
		}
	}
	.powered-by {
		font-style: italic;
		font-family: $font-family-georgia;
		a {
			text-decoration: none;
			color: $footer-powered-by-link-color;
			span {
				color: $carnation;
				font-style: normal;
			}
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
