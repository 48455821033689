.site-map-accordion {
	.accordion-header {
		.accordion-button {
			font-weight: 500 !important;
			text-decoration: none;
			&:not(.collapsed) {
				color: $site-map-accordion-text-color;
				background-color: $site-map-accordion-bg-color;
				&::after {
					background-image: escape-svg(
						url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$site-map-accordion-text-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>")
					);
				}
			}
			&:focus {
				border: none;
				box-shadow: none;
			}
			&:hover {
				color: $site-map-accordion-text-color;
			}
		}
		a.accordion-button::after {
			background-image: none !important;
		}
	}
}
