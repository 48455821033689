.header {
	background-color: $header-bg-color;
}

.header-top {
	font-size: 0.875rem;
	color: $header-text-color;
	.container {
		@include media-breakpoint-down(md) {
			padding-left: 0;
			padding-right: 0;
		}
	}
	.text-sizer {
		a {
			text-decoration: none;
			font-weight: 600;
			color: $header-link-color;
			&.size-1 {
				font-size: 0.8rem;
			}
			&.size-2 {
				font-size: 1rem;
			}
			&.size-3 {
				font-size: 1.2rem;
			}
			&.current-size {
				font-weight: 900;
				text-decoration: underline;
			}
		}
	}
	.popout-search {
		.form-control {
			&:active,
			&:hover,
			&:focus {
				outline: none;
				box-shadow: none;
			}
		}
		.btn {
			border: none;
			color: $header-popout-search-icon-color;
			&:active,
			&:hover,
			&:focus {
				outline: none;
				box-shadow: none;
			}
		}
	}
	.header-links {
		background-color: $header-links-bg-color;
		a {
			font-weight: 700;
			color: $header-links-link-color;
		}
		.profile a {
			&::after {
				font-family: "pbn-icons";
				content: $pbn-icon-member;
				margin-left: 0.25rem;
				display: inline-block;
			}
		}
		.logout a {
			&::after {
				font-family: "pbn-icons";
				content: $pbn-icon-logout;
				margin-left: 0.25rem;
				display: inline-block;
			}
		}
		.login-form-dropdown {
			border-color: $border-color-dark;
			font-size: 0.875rem;
			.form-label {
				margin-bottom: 0;
			}
		}
	}
	.hide-visit {
		background-color: $header-hide-visit-icon-bg-color;
		position: absolute;
		top: 0px;
		right: 0px;
		z-index: 999;
		border-bottom: 2px solid $header-hide-visit-icon-bg-color;
		a {
			border: 0;
			color: $header-hide-visit-icon-color;
			font-weight: 500;
			&:hover {
				border-color: $header-hide-visit-icon-bg-color;
			}
		}
		&.fixed {
			position: fixed;
		}
	}
}

.header-main {
	padding: 2rem 0 2rem 0;
	.header-logo {
		img {
			max-height: 75px;
		}
	}
	.header-search {
		input {
			border: 0;
			border-bottom: 2px solid $header-search-border-color;
			background: transparent;
			box-shadow: none;
			outline: none;
		}
		.btn {
			background-color: $header-search-icon-bg-color;
			color: $header-search-icon-color;
		}
	}
	.powered-by {
		font-style: italic;
		font-family: $font-family-georgia;
		a {
			text-decoration: none;
			color: $header-powered-by-link-color;
			span {
				color: $carnation;
				font-style: normal;
			}
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.language-selector {
		margin-top: 0.5rem;
		.btn {
			color: $header-lang-icon-color;
			border: none;
			outline: none;
			box-shadow: none;
			padding-top: 0.25rem;
			padding-bottom: 0.25rem;
			padding-right: 0;
			font-weight: 600;
			.language-label {
				font-size: 0.875rem;
				margin-right: 0.25rem;
			}
		}
		.language-dropdown {
			background-color: $header-lang-dropdown-bg-color;
			border: 0;
			min-width: 300px;
			a {
				color: $header-lang-dropdown-link-color;
			}
			.smaller {
				font-size: 80% !important;
			}
			.smallest {
				font-size: 60% !important;
			}
		}
	}
}

.header-nav {
	.navbar {
		background-color: $header-navbar-bg-color;
		padding: 0;
		.container {
			@include media-breakpoint-down(md) {
				padding-left: 0;
				padding-right: 0;
			}
		}
		.navbar-toggler {
			color: $header-navbar-link-color;
			padding: 0.5rem 0;
			border: none;
			outline: none;
			box-shadow: none;
			span {
				width: auto;
				font-size: 1.5rem;
			}
			&.collapsed {
				.pbn-icon-menu-light {
					display: inline;
				}
				.pbn-icon-cross-light {
					display: none;
				}
			}
			&:not(.collapsed) {
				.pbn-icon-menu-light {
					display: none;
				}
				.pbn-icon-cross-light {
					display: inline;
				}
			}
		}
		.navbar-collapse {
			.navbar-nav {
				margin-left: -0.5rem;
				@include media-breakpoint-down(md) {
					background-color: $header-navbar-mobile-bg-color;
					margin-left: 0;
				}
				.nav-item {
					@include media-breakpoint-down(md) {
						border-bottom: 1px solid $border-color-dark;
						padding-left: 1.5rem;
					}
					.nav-link {
						font-size: 0.875rem;
						color: $header-navbar-mobile-link-color;
						@include media-breakpoint-up(md) {
							color: $header-navbar-link-color;
							text-align: center;
						}
						@include media-breakpoint-up(lg) {
							font-size: 1rem;
						}
					}
					&:hover {
						.nav-link {
							text-decoration: underline;
						}
					}
				}
				.dropdown-menu {
					background-color: $header-navbar-dropdown-bg-color;
					border: 0;
					margin-top: 0;
					padding-top: 0;
					padding-bottom: 0;
					@include media-breakpoint-down(md) {
						padding-bottom: 0.875rem;
					}
					.dropdown-item {
						color: $header-navbar-dropdown-link-color;
						padding: 0.35rem 0.875rem;
						&:hover,
						&:focus {
							text-decoration: underline;
							background-color: $header-navbar-dropdown-link-hover-bg-color;
						}
						@include media-breakpoint-down(md) {
							margin-right: 0.875rem;
							width: auto;
						}
					}
				}
			}
		}
		.mobile-search {
			input {
				box-shadow: none;
				outline: none;
			}
			.btn {
				border: none;
			}
		}
		.nav-item {
			&.home {
				.nav-link {
					text-align: left;
					span {
						font-size: 1rem;
					}
					&:hover {
						text-decoration: none !important;
					}
				}
			}
		}
	}
}
