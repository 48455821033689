@font-face {
	font-family: "Libre Franklin";
	src: url("../../carousel/fonts/LibreFranklin-VariableFont_wght.woff2")
			format("woff2"),
		url("../../carousel/fonts/LibreFranklin-VariableFont_wght.ttf")
			format("truetype");
	font-style: normal;
	font-weight: 100 900;
}
@font-face {
	font-family: "Libre Franklin";
	src: url("../../carousel/fonts/LibreFranklin-Italic-VariableFont_wght.woff2")
			format("woff2"),
		url("../../carousel/fonts/LibreFranklin-Italic-VariableFont_wght.ttf")
			format("truetype");
	font-style: italic;
	font-weight: 100 900;
}
