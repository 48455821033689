#content {
	.case {
		.content-detail {
			.left-column {
				&:not(.bottom) {
					.review-instructions {
						color: $case-detail-review-text-color;
						font-weight: 600;
					}
					.accept-instructions {
						color: $case-detail-accept-text-color;
						font-weight: 600;
					}
					.close-instructions {
						color: $case-detail-close-text-color;
						font-weight: 600;
					}
				}
				&.bottom {
					.admin-section {
						background-color: $case-detail-history-bg-color;
						padding: 0.25rem 0.5rem;
						margin-bottom: 1rem;
						h4 {
							margin-bottom: 0;
						}
						ul {
							@extend .list-unstyled;
							margin-bottom: 0;
							li {
								div {
									display: inline-block;
									margin-right: 0.5rem;
									font-weight: 600;
								}
								strong {
									font-weight: 400;
								}
							}
						}
					}
				}
			}
			.right-column {
				> p {
					margin-bottom: 0;
				}
				.volunteer-request {
					&.review {
						a {
							@extend .btn-success;
						}
					}
					&.accept {
						a {
							@extend .btn-danger;
						}
					}
					&.close {
						a {
							@extend .btn-primary;
						}
					}
				}
				.next-date {
					@extend .rounded-2, .list-unstyled;
					background-color: $case-detail-next-date-bg-color;
					margin-top: 1rem;
					padding: 0.25rem 0.5rem;
				}
				.time-commitment {
					@extend .rounded-2;
					background-color: $case-detail-time-commitement-bg-color;
					padding: 0.25rem 0.5rem;
					width: 100%;
					display: table;
					text-align: center;
					.EstimatedTimeCommittment {
						.right-column-heading {
							text-align: left;
							margin-bottom: 1rem;
						}
						ul {
							@extend .list-inline;
							padding-left: 0.5rem;
							li {
								@extend .list-inline-item;
								&.horizontal-list {
									float: left;
									width: 20%;
									border-top: 3px solid $white;
									padding: 0.5rem 0;
									margin: 0 0 0.4rem 0;
									line-height: 8px;
									font-size: 0.75rem;
									font-weight: 400;
									&::before {
										content: " ";
										border-radius: 20px;
										border: 3px solid $white;
										width: 8px;
										height: 8px;
										font-size: 8px;
										padding: 0 5px;
										position: relative;
										left: 0;
										right: auto;
										top: -16px;
										z-index: 1;
										background: $case-detail-time-commitement-bg-color;
										&::after {
											position: absolute;
											top: -3px;
											right: 0;
											height: 3px;
											width: 50%;
											background-color: $case-detail-time-commitement-bg-color;
											content: "";
										}
									}
									&:nth-last-child(2) {
										position: relative;
										border-top: 3px $white solid;
										padding-top: 8px;
									}
									&:last-child {
										position: relative;
										border-top: none;
										padding-top: 11px;
									}
									&.chosen {
										&::before {
											background-color: $black;
										}
									}
									strong {
										display: block;
									}
								}
							}
						}
					}
				}
				.contact-box {
					@extend .list-unstyled;
					.contact {
						font-weight: 600;
						.vcard {
							font-weight: 400;
						}
					}
				}
			}
		}
	}
}
