// Icon class
.icon-link {
	text-transform: lowercase;
	position: relative;
	padding-top: 1.25rem;
	&::before {
		font-family: "pbn-icons";
		font-size: 1rem;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: inherit;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		display: block;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, 0);
	}
}

// Admin controls
#main,
#content {
	.edit-link,
	.copy-link,
	.delete-link,
	.thickbox {
		@extend .d-none, .d-md-inline;
	}
}

// App header
#m-header {
	#ToolTitle {
		float: none;
	}
	.join-button,
	.back {
		display: none;
	}
	.page-tools {
		@extend .list-inline, .d-print-none, .d-none, .d-md-block;
		margin-left: auto;
		margin-bottom: 0.5rem;
		text-align: right;
		li {
			@extend .list-inline-item;
			margin-left: 0.75rem;
			a {
				@extend .icon-link;
				&.add-folder {
					&::before {
						content: $pbn-icon-folder-add;
					}
				}
				&.add-item {
					&::before {
						content: $pbn-icon-add;
					}
				}
				&.add-multiple {
					&::before {
						content: $pbn-icon-add $pbn-icon-add;
					}
				}
				&.admin,
				&.settings,
				&.widget {
					&::before {
						content: $pbn-icon-settings;
					}
				}
				&.email,
				&.letter {
					&::before {
						content: $pbn-icon-email;
					}
				}
				&.external-content {
					&::before {
						content: $pbn-icon-external-link;
					}
				}
				&.favorites,
				&.flag-on,
				&.flag-off {
					&::before {
						content: $pbn-icon-bookmarks;
					}
				}
				&.ical {
					&::before {
						content: $pbn-icon-calendar;
					}
				}
				&.hide-sidebar {
					&::before {
						content: $pbn-icon-cross-light;
					}
				}
				&.home {
					&::before {
						content: $pbn-icon-home;
					}
				}
				&.labels {
					&::before {
						content: $pbn-icon-tags;
					}
				}
				&.mailing-definitions {
					&::before {
						content: $pbn-icon-inbox;
					}
				}
				&.member {
					&::before {
						content: $pbn-icon-member;
					}
				}
				&.my-cases {
					&::before {
						content: $pbn-icon-cases;
					}
				}
				&.print {
					content: "print page";
					&::before {
						content: $pbn-icon-printer;
					}
				}
				&.reports {
					&::before {
						content: $pbn-icon-resources;
					}
				}
				&.rss {
					&::before {
						content: $pbn-icon-rss;
					}
				}
			}
		}
	}
}

// Index page
.related-content {
	.filters {
		display: flex;
		background-color: $filter-bar-bg-color;
		padding: 0.5rem;
		color: $filter-bar-text-color;
		margin-bottom: 0.75rem;
		@include media-breakpoint-down(md) {
			font-size: 0.9rem;
			width: fit-content;
			margin-left: auto;
		}
		.filter-indicator {
			cursor: pointer;
			font-weight: 600;
			text-transform: uppercase;
			&::after {
				font-family: "pbn-icons";
				font-size: 0.75rem;
				margin-left: 0.25rem;
				content: $pbn-icon-arrow-right-2;
			}
		}
		.filter-indicator + span {
			display: flex;
			@extend .flex-wrap;
		}
		.rcMenu {
			cursor: pointer;
			margin-left: 1rem;
			position: relative;
			.hotspot {
				&::after {
					font-family: "pbn-icons";
					font-size: 0.75rem;
					margin-left: 0.25rem;
					content: $pbn-icon-arrow-down;
				}
			}
			@include media-breakpoint-down(md) {
				margin-left: 0.5rem;
			}
			ul {
				background-color: $filter-bar-dropdown-bg-color;
				position: absolute;
				top: 30px;
				left: 0;
				right: auto;
				max-height: 250px;
				overflow-y: auto;
				overflow-x: hidden;
				z-index: 1000;
				margin: 0;
				list-style: none;
				text-align: left;
				padding: 0;
				font-size: 0.9rem;
				line-height: 1.25rem;
				min-width: 200px;
				li {
					padding: 0.2rem 2.5rem 0.2rem 0;
					position: relative;
					&:hover {
						background-color: $filter-bar-dropdown-hover-bg-color;
					}
					&::before {
						border: 1px solid $filter-bar-text-color;
						content: "";
						width: 12px;
						height: 12px;
						display: inline-block;
						position: absolute;
						left: 0.5rem;
						top: 0.4rem;
					}
					span {
						position: relative;
						left: 1.75rem;
					}
					&.rcSelected {
						&::before {
							background-color: $filter-bar-text-color;
						}
					}
				}
				@include media-breakpoint-down(md) {
					top: 22px;
					right: -20px;
					left: auto;
					min-width: 150px;
				}
			}
			&.radio {
				ul {
					li {
						&::before {
							border-radius: 50%;
						}
					}
				}
			}
		}
	}
	.showing {
		font-size: 0.9rem;
		margin-bottom: 0.5rem;
	}
	.selected-filters {
		font-size: 0.9rem;
		display: flex;
		flex-wrap: wrap;
		.selected-filter {
			cursor: pointer;
			background-color: $selected-filters-bg-color;
			margin-right: 0.5rem;
			padding: 0.1rem 0.3rem 0.1rem 0.3rem;
			margin-bottom: 0.75rem;
		}
	}
	.list ul {
		list-style: none;
		padding-left: 0;
	}
	.link {
		cursor: pointer;
		color: $link-color;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	.content-alert-message {
		background-color: $result-item-alert-bg-color;
		color: $white;
		font-weight: 600;
		padding: 0.25rem 0.5rem;
		border-top: 1px solid $result-item-alert-border-color;
		text-transform: uppercase;
		&::before {
			font-family: "pbn-icons";
			font-size: 0.9rem;
			margin-right: 0.25rem;
			content: $pbn-icon-info;
		}
	}
	.content-info {
		border-top: 1px solid $result-item-border-color;
		padding: 1rem 0 1rem 0;
		width: 100%;
		float: none;
		.content-title {
			font-size: 1.25rem;
			font-weight: 600;
		}
		.props {
			li {
				margin-right: 0.5rem;
				display: inline-block;
				&:not(:last-child)::after {
					content: "•";
					margin-left: 0.5rem;
				}
			}
		}
		&.alert-item {
			border-top: none;
			padding: 1rem;
			background-color: $result-item-featured-bg-color;
			margin-bottom: 1rem;
		}
	}
	.pageControls {
		font-size: 0.9rem;
		display: flex;
		justify-content: space-between;
		margin-bottom: 1rem;
		#pageDecrementor::after {
			content: " Back";
		}
		#pageIncrementor::before {
			content: "Next ";
		}
	}
}

// Detail pages
#content {
	.article,
	.event,
	.case,
	.resource,
	.oppsguide .organization,
	.project {
		@extend .row;
		margin-top: 2rem;
		a {
			@extend .text-break;
		}
		img {
			@extend .img-fluid;
		}
		.detail-tools {
			//@extend .d-none, .d-md-block, .col-12, .col-md-2, .d-print-none;
			display: none !important;
			text-align: center;
			padding: 1rem;
			font-size: 0.75rem;
			ul {
				@extend .list-unstyled;
				width: 70px;
				margin: 0 auto 0 auto;
				li {
					margin-bottom: 2rem;
					display: list-item !important;
					.download {
						@extend .icon-link;
						&::before {
							content: $pbn-icon-download;
						}
					}
					&:has(.print) {
						display: none !important;
					}
					.letter,
					.email {
						@extend .icon-link;
						&::before {
							content: $pbn-icon-email;
						}
					}
				}
			}
		}
		.content-detail {
			//@extend .col-12, .col-md-10;
			@extend .col-12;
			.props {
				@extend .list-unstyled;
				.author {
					strong {
						font-weight: 400;
					}
				}
				.address,
				.org {
					font-weight: 600;
					strong {
						font-weight: 400;
					}
				}
				.link {
					&::after {
						font-family: "pbn-icons";
						content: $pbn-icon-external-link;
					}
				}
				.contact {
					margin-top: 1rem;
					font-weight: 600;
					.vcard {
						font-weight: 400;
						.org {
							font-weight: 400;
						}
					}
				}
			}
			.topics {
				strong {
					font-weight: 600;
				}
				ul {
					@extend .list-inline;
					li {
						display: inline-block;
						&:not(:last-child)::after {
							content: ", ";
						}
					}
				}
			}
		}
	}
}

#content {
	.case,
	.oppsguide .organization,
	.project {
		.content-detail {
			@extend .d-flex, .flex-wrap;
			.left-column {
				padding-right: 0;
				height: fit-content;
				@include media-breakpoint-up(md) {
					padding-right: 1rem;
				}
				@extend .col-12, .col-md-8, .order-1;
				&.bottom {
					@extend .order-2, .order-md-3;
					font-size: 0.9rem;
					.props {
						.boolean {
							background-color: $item-detail-boolean-unchecked-bg-color;
							color: $item-detail-boolean-unchecked-text-color;
							border-bottom: 2px solid $white;
							padding: 0.25rem 0.5rem;
							&::before {
								font-family: "pbn-icons";
								content: $pbn-icon-cross;
								margin-right: 0.5rem;
								font-size: 0.9rem;
							}
							&.checked {
								background-color: $item-detail-boolean-checked-bg-color;
								color: $item-detail-boolean-checked-text-color;
								&::before {
									content: $pbn-icon-checkmark;
								}
							}
						}
						.long-answers {
							margin-top: 1rem;
							li {
								@extend .d-flex, .flex-row;
								border-bottom: 2px solid $white;
								div {
									@extend .col-5;
									border-right: 2px solid $white;
									background-color: $item-detail-lookup-label-bg-color;
									color: $item-detail-lookup-label-text-color;
									text-align: right;
									padding: 0.25rem 0.5rem;
								}
								strong {
									@extend .col-7;
									background-color: $item-detail-lookup-value-bg-color;
									color: $item-detail-lookup-value-text-color;
									font-weight: 400;
									padding: 0.25rem 0.5rem;
								}
							}
						}
					}
				}
			}
			.right-column {
				@extend .col-12, .col-md-4, .order-3, .order-md-2;
				padding-left: 0;
				@include media-breakpoint-up(md) {
					padding-left: 1rem;
				}
				font-size: 0.9rem;
				.volunteer-request {
					a {
						@extend .btn;
						width: 100%;
					}
				}
				&.bottom {
					@extend .order-4;
				}
			}
		}
	}
}
